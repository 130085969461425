<!-- src/views/LogisticsView.vue -->
<template>
  <div v-if="isAuthenticated" class="logistics-view">
    <!-- Mensaje de Error -->
    <div v-if="logiStore.error" class="error-container">
      <p>Error: {{ logiStore.error }}</p>
    </div>

    <!-- Componente de la Tabla -->
    <RealTimeTable v-else />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useLogiStore } from "../stores/LogiStore";
import { useAuthStore } from "@/stores/authStore";
import RealTimeTable from "../components/RealTimeTable.vue";

const logiStore = useLogiStore();
const authStore = useAuthStore();
const isAuthenticated = ref(authStore.isAuthenticated);

onMounted(() => {
  logiStore.fetchData();
});
</script>

<style scoped>
.logistics-view {
  position: relative;
  min-height: 100vh;
  padding: 20px;
}

/* Estilos para la Barra de Progreso */
.progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #f3f3f3;
  z-index: 9999;
}

.progress-bar {
  width: 0%;
  height: 100%;
  background-color: #3b95f5;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    width: 0%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

/* Estilos para el Mensaje de Error */
.error-container {
  color: red;
  text-align: center;
  margin-top: 20px;
}
</style>
