<template>
  <div id="map" style="height: 100%; width: 100%"></div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useDatosStore } from "@/stores/datosStore";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: new URL(
    "leaflet/dist/images/marker-icon-2x.png",
    import.meta.url
  ).href,
  iconUrl: new URL("leaflet/dist/images/marker-icon.png", import.meta.url).href,
  shadowUrl: new URL("leaflet/dist/images/marker-shadow.png", import.meta.url)
    .href,
});

export default {
  name: "MapComponent",
  props: {
    visibleDevices: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["data-loaded"],
  setup(props, { emit }) {
    const datosStore = useDatosStore();
    const map = ref(null);
    const markersCluster = ref(null);

    const updateMarkerCluster = () => {
      if (!markersCluster.value) return;
      markersCluster.value.clearLayers();

      // Ahora usamos la lista recibida desde la tabla, ya filtrada y paginada
      props.visibleDevices.forEach((device) => {
        if (
          device.latitude &&
          device.longitude &&
          !isNaN(device.latitude) &&
          !isNaN(device.longitude)
        ) {
          const marker = L.marker([device.latitude, device.longitude]);
          marker.bindPopup(`
            <div>
              <strong>${device.Name}</strong><br />
              ${device.Location || "Sin ubicación"}
            </div>
          `);
          markersCluster.value.addLayer(marker);
        }
      });

      emit("data-loaded");
    };

    onMounted(async () => {
      map.value = L.map("map").setView([42.8468, -2.6727], 12);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(map.value);

      markersCluster.value = L.markerClusterGroup();
      map.value.addLayer(markersCluster.value);

      // Cargamos todos los datos
      await datosStore.fetchTodosDatos();

      // Filtramos inicialmente según los bounds actuales
      const bounds = map.value.getBounds();
      const mapBounds = {
        southWest: {
          lat: bounds.getSouthWest().lat,
          lng: bounds.getSouthWest().lng,
        },
        northEast: {
          lat: bounds.getNorthEast().lat,
          lng: bounds.getNorthEast().lng,
        },
      };
      datosStore.actualizarDatosEnBounds(mapBounds);

      updateMarkerCluster();

      // Cada vez que se mueva el mapa, actualizamos datosEnBounds, lo que a su vez afectará a la tabla.
      map.value.on("moveend", () => {
        const newBounds = map.value.getBounds();
        datosStore.actualizarDatosEnBounds({
          southWest: {
            lat: newBounds.getSouthWest().lat,
            lng: newBounds.getSouthWest().lng,
          },
          northEast: {
            lat: newBounds.getNorthEast().lat,
            lng: newBounds.getNorthEast().lng,
          },
        });
      });
    });

    // Observar cambios en la prop visibleDevices para actualizar marcadores
    watch(
      () => props.visibleDevices,
      () => {
        updateMarkerCluster();
      },
      { deep: true }
    );

    return {};
  },
};
</script>

<style scoped>
#map {
  height: 100%;
}
</style>
