<template>
  <v-card class="floorplan-card" flat>
    <v-card-text class="floorplan-card-text">
      <!-- Contenedor de Selectores -->
      <div class="selectors-container">
        <v-select
          v-model="selectedMontaje"
          :items="montajeOptions"
          label="Seleccionar Montaje"
          dense
          outlined
          class="selector"
        />
        <v-select
          v-model="selectedFamilia"
          :items="familiaOptions"
          label="Seleccionar Familia"
          dense
          outlined
          class="selector"
          :disabled="selectedMontaje !== 'Montaje Bruto'"
        />
      </div>

      <!-- Contenedor del Stage de Konva -->
      <div v-if="selectedFamilia" class="konva-container" ref="containerRef">
        <v-stage :config="stageConfig" class="konva-stage">
          <v-layer>
            <!-- Imagen de Fondo -->
            <v-image :config="imageConfig" />

            <!-- Dibujar Flechas (Edges) -->
            <v-line
              v-for="edge in edges"
              :key="`${edge.from}-${edge.to}`"
              :config="getEdgeConfig(edge)"
            />

            <!-- Dibujar Nodos con número de dispositivos en el centro -->
            <v-group
              v-for="nodo in nodosData"
              :key="nodo.id"
              :config="getNodeConfig(nodo)"
              @click="handleNodeClick(nodo)"
            >
              <v-circle :config="getCircleConfig(nodo)" />
              <v-text :config="getNodeTextConfig(nodo)" />
            </v-group>

            <!-- Dibujar Etiquetas con Fondo Blanco -->
            <v-group
              v-for="nodo in nodosData"
              :key="`${nodo.id}-label`"
              :config="getLabelConfig(nodo)"
            >
              <v-rect :config="getLabelBackgroundConfig(nodo)" />
              <v-text :config="getLabelTextConfig(nodo)" />
            </v-group>
          </v-layer>
        </v-stage>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { nodos, edges } from "@/data/nodos.js";
import PlanoMB from "@/assets/PlanoMB.png";
import Konva from "konva";
import { useDeviceStore } from "@/stores/deviceStore";
import { useRouter } from "vue-router";

const router = useRouter();

const deviceStore = useDeviceStore();
deviceStore.setFiltroName("20014-");
deviceStore.setFiltroReader("");
deviceStore.setFiltroLocation("");
deviceStore.setFiltroDevicetype(null);

// Datos de ejemplo para el número de dispositivos
const contenedoresPorNodo = computed(() => {
  const countMap = {
    "Descarga Llenos": 0,
    Gestamp: 0,
    "Almacén Lleno": 0,
    "Almacén Vacío": 0,
    Trasvase: 0,
    "En Consumo": 0,
    "Revisión de Calidad": 0,
    "Almacén de Recuperación": 0,
    "En Reparación": 0,
    Perdido: 0,
    "Tránsito Inusual": 0,
  };

  deviceStore.devicesFiltrados.forEach((device) => {
    const location = device.Location || "";
    const statusKey = device.statusKey || "";
    const reader = device.reader || "";

    // Reglas de asignación de nodos
    if (location.endsWith("Descarga-Carga")) countMap["Descarga Llenos"]++;
    if (location.includes("Proveedores / Gestamp")) countMap["Gestamp"]++;
    if (
      location.endsWith("Montaje bruto / Almacen llenos") ||
      reader === "M-08EFAB0000AC8071_ALMACÉN LLENO" ||
      location.endsWith("Almacén lleno")
    )
      countMap["Almacén Lleno"]++;
    if (reader === "M-08EFAB0000AC8071_ALMACÉN LLENO")
      countMap["Almacén Vacío"]++;
    if (location.endsWith("Montaje bruto / Reparacion")) countMap["Trasvase"]++;
    if (
      location.endsWith("Montaje bruto / Consumo") ||
      location.endsWith("Consumo")
    )
      countMap["En Consumo"]++;
    if (
      location.endsWith("Montaje bruto / Revision calidad") ||
      location.endsWith("Revisión calidad")
    )
      countMap["Revisión de Calidad"]++;
    if (location.endsWith("Montaje bruto / Almacen reparados"))
      countMap["Almacén de Recuperación"]++;
    if (location.includes("Axia Grupo")) countMap["En Reparación"]++;
    if (statusKey === "LOST") countMap["Perdido"]++;
    if (statusKey === "UNUSUALTRANSIT") countMap["Tránsito Inusual"]++;
  });

  return countMap;
});

const containerRef = ref(null);

// Configuración inicial del Stage
const stageConfig = ref({
  width: window.innerWidth,
  height: window.innerHeight,
  draggable: false,
  scaleX: 1,
  scaleY: 1,
});

// Configuración de la Imagen
const imageConfig = ref({
  x: 0, // Eliminado el cálculo para centrar verticalmente
  y: 0, // Sin margen superior
  image: null,
  width: 800,
  height: 600,
  draggable: false,
});

const nodosData = ref([...nodos]);

// Datos para los selectores de montaje y familia
const montajeOptions = ["Montaje Bruto", "Montaje Final"];
const selectedMontaje = ref(null);
const familiaOptions = ["20014"];
const selectedFamilia = ref(null);

// Cargar la imagen de fondo en tamaño fijo
const loadImage = () => {
  const img = new window.Image();
  img.src = PlanoMB;

  img.onload = () => {
    imageConfig.value.image = img;
    adjustStageSize();
  };

  img.onerror = () => {
    console.error("Error al cargar la imagen de fondo");
  };
};

// Posiciones de nodos en relación con el tamaño de la imagen
const updateNodePositions = () => {
  nodosData.value.forEach((nodo) => {
    switch (nodo.id) {
      case "Descarga Llenos":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 1;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.53;
        break;
      case "Gestamp":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 1;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.15;
        break;
      case "Almacén Lleno":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 0.63;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.58;
        break;
      case "Almacén Vacío":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 0.57;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.7;
        break;
      case "Trasvase":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 0.51;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.28;
        break;
      case "En Consumo":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 0.64;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.79;
        break;
      case "Revisión de Calidad":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 0.44;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.16;
        break;
      case "Almacén de Recuperación":
        nodo.x = imageConfig.value.x + imageConfig.value.width * 0.18;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.85;
        break;
      case "En Reparación":
        nodo.x = imageConfig.value.x - imageConfig.value.width * 0.05;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.34;
        break;
      case "Perdido":
        nodo.x = imageConfig.value.x - imageConfig.value.width * 0.05;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.1;
        break;
      case "Tránsito Inusual":
        nodo.x = imageConfig.value.x - imageConfig.value.width * 0.05;
        nodo.y = imageConfig.value.y + imageConfig.value.height * 0.22;
        break;
      default:
        break;
    }
  });
};

const getEdgeConfig = (edge) => {
  const fromNode = nodosData.value.find((n) => n.id === edge.from);
  const toNode = nodosData.value.find((n) => n.id === edge.to);

  if (!fromNode || !toNode) return {};

  return {
    points: [fromNode.x, fromNode.y, toNode.x, toNode.y],
    stroke: "black",
    strokeWidth: 2,
    lineCap: "round",
    lineJoin: "round",
    pointerEvents: "none",
    arrow: true,
  };
};

// Configuración de nodos y número de dispositivos
const getNodeConfig = (nodo) => ({
  x: nodo.x,
  y: nodo.y,
  draggable: false,
});

const getCircleConfig = () => ({
  x: 0,
  y: 0,
  radius: 20,
  fill: "green",
  stroke: "black",
  strokeWidth: 2,
  draggable: false,
});

const getNodeTextConfig = (nodo) => {
  const count = String(contenedoresPorNodo.value[nodo.id] || "0");
  const textWidth = 10 * count.length; // Cada dígito ocupa 10px de ancho
  const baseWidth = 20; // Ancho base para 2 dígitos o menos
  const totalWidth = Math.max(baseWidth, textWidth); // Aseguramos un mínimo de ancho
  return {
    x: -totalWidth / 2, // Centrado horizontalmente
    y: -10, // Centrado verticalmente
    text: count,
    fontSize: 14,
    fontFamily: "Arial",
    fill: "white",
    align: "center",
    width: totalWidth, // Usar el ancho calculado
    height: 20, // Mantener la altura fija
    pointerEvents: "none",
  };
};

// Ajuste del texto y fondo para centrarlo al nodo
const getLabelTextConfig = (nodo) => {
  const text = nodo.id;
  const tempText = new Konva.Text({
    text: text,
    fontSize: 14,
    fontFamily: "Arial",
    padding: 5,
  });
  const textWidth = tempText.width();

  return {
    x: -textWidth / 2 - 4, // Centrado horizontalmente
    y: -48, // Posicionado ligeramente encima del nodo
    text: nodo.id,
    fontSize: 14,
    fontFamily: "Arial",
    fill: "black",
    align: "center",
    width: textWidth, // El ancho se ajusta automáticamente al texto
    padding: 5, // Margen adicional alrededor del texto
    pointerEvents: "none",
  };
};

const getLabelBackgroundConfig = (nodo) => {
  const text = nodo.id;
  const tempText = new Konva.Text({
    text: text,
    fontSize: 14,
    fontFamily: "Arial",
    padding: 5,
  });
  const textWidth = tempText.width();

  return {
    x: -textWidth / 2 - 5, // Centrado respecto al texto y al nodo
    y: -50, // Posicionado ligeramente encima del nodo
    width: textWidth + 10,
    height: 24, // Altura del rectángulo para ajustarse al texto
    fill: "white",
    opacity: 0.8,
    cornerRadius: 5,
    pointerEvents: "none",
  };
};

// Posicionar el grupo de la etiqueta y ajustarlo en el borde
const getLabelConfig = (nodo) => ({
  x: nodo.x,
  y: nodo.y,
  draggable: false,
});

const handleNodeClick = (nodo) => {
  console.log("Nodo clickeado:", nodo.id);
  switch (nodo.id) {
    case "Descarga Llenos":
      deviceStore.setFiltroLocation("Descarga-Carga");
      break;
    case "Almacén Lleno":
      deviceStore.setFiltroLocation("Almacen llenos");
      break;
    case "Almacén Vacío":
      deviceStore.setFiltroReader("M-08EFAB0000AC8071_ALMACÉN LLENO");
      break;
    case "Trasvase":
      deviceStore.setFiltroLocation("Montaje bruto / Reparacion");
      break;
    case "En Consumo":
      deviceStore.setFiltroLocation("Consumo");
      break;
    case "Revisión de Calidad":
      deviceStore.setFiltroLocation("Calidad");
      break;
    case "Almacén de Recuperación":
      deviceStore.setFiltroLocation("Montaje bruto / Almacen reparados");
      break;
    case "En Reparación":
      deviceStore.setFiltroLocation("Axia");
      break;
    case "Perdido":
      deviceStore.setFiltroLocation("");
      break;
    case "Tránsito Inusual":
      deviceStore.setFiltroLocation("");
      break;
    default:
      deviceStore.setFiltroLocation(nodo.id);
      break;
  }
  router.push({ name: "Inventory" });
};

// Ajustar el tamaño del Stage al tamaño de la ventana y centrar la imagen horizontalmente
const adjustStageSize = () => {
  stageConfig.value.width = window.innerWidth;
  stageConfig.value.height = window.innerHeight;

  // Recalcular la posición horizontal de la imagen para centrarla
  imageConfig.value.x = (stageConfig.value.width - imageConfig.value.width) / 2;
  imageConfig.value.y = 0; // Sin margen superior

  updateNodePositions();
};

onMounted(() => {
  loadImage();
  window.addEventListener("resize", adjustStageSize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", adjustStageSize);
});
</script>

<style scoped>
.floorplan-card,
.floorplan-card-text,
.konva-container,
.konva-stage {
  overflow: visible;
}

.floorplan-card {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.floorplan-card-text {
  flex: 1;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Contenedor de Selectores */
.selectors-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* Aumentado para más espacio entre selectores */
  z-index: 10;
  width: 250px;
  /* Aumentado para más espacio */
}

/* Estilos para los selectores */
.selector {
  min-width: 150px;
  /* Aumentado para mayor visibilidad */
}

/* Contenedor de Konva */
.konva-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* Cambiado de center a flex-start para alinear al inicio verticalmente */
  justify-content: center;
  overflow: hidden;
}

/* Stage de Konva que ocupa todo el espacio disponible */
.konva-stage {
  border: none;
  overflow: hidden;
}
</style>
