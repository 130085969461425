import { defineStore } from "pinia";
import api from "@/services/api";

export const useDatosStore = defineStore("datosStore", {
  state: () => ({
    datos: [], // Todos los dispositivos cargados
    datosEnBounds: [], // Dispositivos filtrados por los límites del mapa
    filtroNombre: "",
    filtroDeviceType: null,
  }),
  getters: {
    datosFiltrados(state) {
      let datos = state.datosEnBounds; // Solo dispositivos dentro de los límites visibles
      if (state.filtroNombre) {
        datos = datos.filter((dato) =>
          dato.Name?.toLowerCase().includes(state.filtroNombre.toLowerCase())
        );
        this.datosEnBounds = this.datosEnBounds.filter((dato) =>
          dato.Name?.toLowerCase().includes(state.filtroNombre.toLowerCase())
        );
      }
      if (state.filtroDeviceType) {
        datos = datos.filter(
          (dato) => String(dato.DeviceType) === String(state.filtroDeviceType)
        );
        this.datosEnBounds = this.datosEnBounds.filter(
          (dato) => String(dato.DeviceType) === String(state.filtroDeviceType)
        );
      }
      return datos;
    },
  },
  actions: {
    setFiltroNombre(nuevoFiltro) {
      this.filtroNombre = nuevoFiltro;
    },
    setFiltroDeviceType(nuevoFiltro) {
      this.filtroDeviceType = nuevoFiltro;
    },
    async fetchTodosDatos() {
      // Cargar todos los dispositivos al iniciar
      try {
        const devices = await api.getAllDevices();
        this.datos = devices.map((device) => ({
          ...device,
          Location: device.fields?.LOCATION || "Sin ubicación",
        }));
        console.log("Todos los dispositivos cargados.");
      } catch (error) {
        console.error("Error al cargar todos los dispositivos:", error);
      }
    },
    actualizarDatosEnBounds(bounds) {
      // Filtrar dispositivos dentro de los límites visibles
      const { southWest, northEast } = bounds;
      this.datosEnBounds = this.datos.filter((dato) => {
        return (
          dato.latitude >= southWest.lat &&
          dato.latitude <= northEast.lat &&
          dato.longitude >= southWest.lng &&
          dato.longitude <= northEast.lng
        );
      });
      console.log(`Datos en bounds actualizados: ${this.datosEnBounds.length}`);
    },
  },
});
