<!-- src/views/ControlView.vue -->
<template>
  <v-container
    v-if="isAuthenticated"
    fluid
    class="fill-height d-flex align-center justify-center"
  >
    <v-row class="d-flex flex-column align-center">
      <!-- Botón Dar de alta contenedor -->
      <v-btn
        class="my-3 button-responsive"
        color="primary"
        large
        @click="showAltaNotification"
      >
        Dar de alta contenedor
      </v-btn>
      <!-- Mensaje de desarrollo -->
      <v-alert v-if="showNotification" type="info" class="my-2">
        Esta funcionalidad aún está en desarrollo.
      </v-alert>

      <!-- Botón Control de activo -->
      <v-btn
        class="my-3 button-responsive"
        color="primary"
        large
        @click="goToHome"
      >
        Control de activo
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";

export default {
  name: "ControlView",
  setup() {
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);
    const router = useRouter();
    const showNotification = ref(false);

    onMounted(() => {
      if (!authStore.isAuthenticated) {
        router.push({ name: "Login" });
      }
    });

    // Función para mostrar el mensaje de desarrollo
    const showAltaNotification = () => {
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 3000); // Ocultar después de 3 segundos
    };

    // Función para ir a HomeView
    const goToHome = () => {
      router.push({ name: "Home" });
    };

    return {
      isAuthenticated,
      showNotification,
      showAltaNotification,
      goToHome,
    };
  },
};
</script>

<style scoped>
.v-btn {
  font-size: 1.5rem; /* Aumentar el tamaño del texto del botón */
  width: 500px; /* Ajustar el ancho del botón */
  height: 80px; /* Ajustar la altura del botón */
}

@media (max-width: 600px) {
  .button-responsive {
    width: 100%; /* Ajustar el ancho del botón al 100% en pantallas pequeñas */
    font-size: 1.2rem; /* Reducir el tamaño del texto del botón en pantallas pequeñas */
    height: 60px; /* Reducir la altura del botón en pantallas pequeñas */
  }
}
</style>
