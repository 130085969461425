<template>
  <v-container fluid class="fill-height d-flex align-center justify-center">
    <LoginForm />
  </v-container>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "LoginView",
  components: {
    LoginForm,
  },
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí */
</style>
