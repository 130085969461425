<!-- src/components/PieChart.vue -->
<template>
  <div>
    <Pie :chartData="chartData" :options="options" />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default defineComponent({
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    backgroundColors: {
      type: Array,
      default: () => [],
    },
    chartTitle: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const chartData = ref({
      labels: props.labels,
      datasets: [
        {
          label: props.chartTitle,
          data: props.data,
          backgroundColor:
            props.backgroundColors.length > 0
              ? props.backgroundColors
              : [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#4BC0C0",
                  "#9966FF",
                  "#FF9F40",
                ],
        },
      ],
    });

    const options = ref({
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: !!props.chartTitle,
          text: props.chartTitle,
        },
      },
    });

    watch(
      () => [
        props.labels,
        props.data,
        props.backgroundColors,
        props.chartTitle,
      ],
      () => {
        chartData.value.labels = props.labels;
        chartData.value.datasets[0].data = props.data;
        if (props.backgroundColors.length > 0) {
          chartData.value.datasets[0].backgroundColor = props.backgroundColors;
        }
        options.value.plugins.title.display = !!props.chartTitle;
        options.value.plugins.title.text = props.chartTitle;
      }
    );

    return {
      chartData,
      options,
    };
  },
});
</script>

<style scoped>
/* Puedes añadir estilos personalizados aquí si es necesario */
</style>
