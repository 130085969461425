<template>
  <v-app>
    <!-- Menú Lateral -->
    <v-navigation-drawer
      v-model="drawer"
      :rail="rail"
      permanent
      class="pa-2"
      :width="drawer ? 300 : 80"
    >
      <!-- Botón de Expansión -->
      <v-btn icon class="expand-button" @click.stop="rail = !rail">
        <v-icon>{{ rail ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon>
      </v-btn>

      <!-- Título con Ícono -->
      <v-list-item nav>
        <template v-slot:prepend>
          <v-icon large>mdi-folder-multiple</v-icon>
        </template>
        <v-list-item-title class="text-h6">Manuales</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Lista de Categorías y Documentos -->
      <v-list dense>
        <v-list-group
          v-for="(category, catIndex) in filteredCategories"
          :key="`category-${catIndex}`"
          value="category.expanded"
        >
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-folder"
              :title="!rail ? category.title : ''"
            ></v-list-item>
          </template>

          <v-list-item
            v-for="(doc, docIndex) in category.docs"
            :key="`doc-${docIndex}`"
            prepend-icon="mdi-file-document"
            @click="selectDoc(doc.id)"
          >
            <v-list-item-title>{{ doc.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <!-- Área de Visualización del PDF -->
    <v-main>
      <v-container
        fluid
        class="fill-height pa-2"
        style="max-height: calc(100vh - 100px)"
      >
        <v-row class="fill-height">
          <v-col cols="12" class="d-flex flex-column">
            <!-- Spinner de Carga -->
            <div
              v-if="loadingPdf"
              class="d-flex align-center justify-center fill-height"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <!-- Visor de PDF -->
            <div v-else-if="selectedPdfUrl" class="flex-grow-1">
              <iframe
                :src="selectedPdfUrl"
                style="width: 100%; height: 100%"
                frameborder="0"
              ></iframe>
            </div>
            <!-- Mensaje Informativo -->
            <div v-else class="d-flex align-center justify-center fill-height">
              <v-alert type="info"
                >Seleccione un PDF del menú para visualizarlo</v-alert
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import api from "@/services/api.js";
import debounce from "lodash/debounce";

export default {
  name: "ManualesView",
  data() {
    return {
      drawer: true, // Controla si el menú está expandido o colapsado
      rail: false, // Controla si el drawer está en modo rail (colapsado)
      menu: { categories: [] },
      filteredCategories: [],
      searchQuery: "",
      selectedPdfUrl: null,
      loadingPdf: false,
      debouncedFilterMenu: null,
    };
  },
  mounted() {
    this.debouncedFilterMenu = debounce(this.filterMenu, 300);
    this.loadMenu();
  },
  methods: {
    async loadMenu() {
      try {
        const data = await api.getManuales();
        console.log("Menú recibido:", data); // Verifica la estructura
        this.menu = data;

        this.filteredCategories = this.menu.categories.map((category) => ({
          ...category,
          expanded: false, // Inicialmente colapsado
        }));
      } catch (error) {
        console.error("Error al obtener el menú de manuales:", error);
      }
    },
    async selectDoc(docId) {
      this.loadingPdf = true;
      this.selectedPdfUrl = null;
      try {
        const data = await api.getPdfUrl(docId);
        console.log("URL del PDF:", data.url); // Verifica la URL del PDF
        this.selectedPdfUrl = data.url;
      } catch (error) {
        console.error("Error al obtener la URL del PDF:", error);
      } finally {
        this.loadingPdf = false;
      }
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    filterMenu() {
      if (!this.searchQuery) {
        // Si no hay búsqueda, mostrar todas las categorías
        this.filteredCategories = this.menu.categories.map((category) => ({
          ...category,
          expanded: false,
        }));
      } else {
        const query = this.searchQuery.toLowerCase();
        this.filteredCategories = this.menu.categories
          .map((category) => {
            const filteredDocs = category.docs.filter((doc) =>
              doc.title.toLowerCase().includes(query)
            );
            if (filteredDocs.length > 0) {
              return {
                ...category,
                docs: filteredDocs,
                expanded: true, // Expandir las categorías que tienen resultados
              };
            }
            return null;
          })
          .filter((category) => category !== null);
      }
    },
  },
  beforeUnmount() {
    if (this.debouncedFilterMenu) {
      this.debouncedFilterMenu.cancel();
    }
  },
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}

.v-navigation-drawer {
  height: 100vh;
  transition: width 0.3s;
  position: relative; /* Permite posicionar el botón dentro del drawer */
}

.expand-button {
  position: absolute;
  top: 10px;
  right: -20px; /* Botón visible incluso en modo colapsado */
  z-index: 1000;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>
