<!-- src/views/StatusView.vue -->
<template>
  <v-card
    v-if="isAuthenticated"
    class="d-flex flex-column"
    style="height: calc(100vh - 80px); overflow: hidden"
  >
    <!-- Indicador de carga -->
    <v-progress-linear
      :active="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <!-- Título de la tarjeta y filtros -->
    <v-card-title v-if="!loading">
      <v-row class="w-100" no-gutters>
        <!-- Selector de familia -->
        <v-col cols="12" md="6">
          <v-select
            v-model="selectedFamily"
            :items="familyOptions"
            label="Seleccionar Familia"
            item-title="title"
            item-value="value"
            clearable
            dense
            outlined
            @update:model-value="onFamilyChange"
            class="mr-2"
          ></v-select>
        </v-col>

        <!-- Campo de búsqueda por ubicación -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="filterLocation"
            label="Filtrar por Ubicación"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <!-- Tabla con encabezados fijos y altura ajustable -->
    <v-data-table
      v-if="!loading && selectedFamily"
      :headers="headers"
      :items="paginatedData"
      v-model:page="page"
      v-model:items-per-page="itemsPerPage"
      class="elevation-1 flex-grow-1"
      fixed-header
      hide-default-footer
      style="overflow: hidden"
    >
      <!-- Slot personalizado para el pie de página -->
      <template #bottom>
        <v-row align="center" justify="space-between" class="w-100 pa-2">
          <!-- Selector de Filas por Página y Paginación -->
          <v-col cols="12" sm="8" class="d-flex align-center">
            <!-- Selector de Filas por Página -->
            <v-select
              v-model="pagination.itemsPerPage"
              :items="itemsPerPageOptions"
              item-title="title"
              item-value="value"
              label="Filas por página"
              hide-details
              dense
              outlined
              class="mr-4"
              style="max-width: 150px"
            ></v-select>
            <!-- Paginación -->
            <v-pagination
              v-model="pagination.page"
              :length="pageCount"
              total-visible="7"
            ></v-pagination>
            <!-- Indicador del número total de ubicaciones -->
            <span class="ml-4"> Total de ubicaciones: {{ totalDevices }} </span>
          </v-col>
          <!-- Botón de Exportar a Excel -->
          <v-col cols="12" sm="4" class="text-right">
            <v-btn
              color="success"
              dark
              @click="exportarExcel"
              class="mt-2 mt-sm-0"
              small
              aria-label="Exportar a Excel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Exportar a Excel
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, computed, onMounted, watch, reactive } from "vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useAuthStore } from "@/stores/authStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  name: "StatusView",
  setup() {
    const deviceStore = useDeviceStore();
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);

    const loading = ref(true);

    const selectedFamily = ref(deviceStore.filtroFamily);

    // Filtros reactivos vinculados al store
    const filterLocation = computed({
      get: () => deviceStore.filtroLocation,
      set: (value) => {
        deviceStore.filtroLocation = value;
      },
    });

    /*const itemsPerPage = ref(10);
    const page = ref(1);*/
    const pagination = reactive({
      itemsPerPage: 10,
      page: 1,
    });

    const itemsPerPageOptions = [
      { title: "10", value: 10 },
      { title: "20", value: 20 },
      { title: "50", value: 50 },
      { title: "100", value: 100 },
      { title: "500", value: 500 },
      { title: "1000", value: 1000 },
      { title: "Todas", value: -1 },
    ];

    // Computed para las opciones de familias
    const familyOptions = computed(() => {
      return deviceStore.availableFamilies.map((family) => ({
        title: family,
        value: family,
      }));
    });

    // Computed para los datos filtrados
    const filteredData = computed(() => {
      const data = [];
      const devicesCountByLocation = deviceStore.devicesCountByLocation;

      Object.entries(devicesCountByLocation).forEach(([location, value]) => {
        if (
          filterLocation.value &&
          !location.toLowerCase().includes(filterLocation.value.toLowerCase())
        ) {
          return; // Saltar esta ubicación
        }
        const { count, reader } = value;
        data.push({
          location,
          reader,
          count,
        });
      });

      return data;
    });

    // Computed para el número total de ubicaciones
    const totalDevices = computed(() => {
      return filteredData.value.length;
    });

    // Computed para el número total de páginas
    const pageCount = computed(() => {
      if (pagination.itemsPerPage === -1) {
        return 1; // Mostrar todas las filas en una sola página
      }
      return Math.ceil(filteredData.value.length / pagination.itemsPerPage);
    });

    // Computed para los datos paginados
    const paginatedData = computed(() => {
      if (pagination.itemsPerPage === -1) {
        return filteredData.value;
      }
      const start = (pagination.page - 1) * pagination.itemsPerPage;
      const end = start + pagination.itemsPerPage;
      return filteredData.value.slice(start, end);
    });

    // Watchers para reiniciar la página cuando cambian los filtros o itemsPerPage
    watch([pagination.itemsPerPage, filterLocation, selectedFamily], () => {
      pagination.page = 1; // Reiniciar a la primera página
    });

    // Actualizar el store cuando se selecciona una familia
    const onFamilyChange = (family) => {
      deviceStore.setFiltroFamily(family);
      pagination.page = 1;
    };

    // Función para exportar a Excel
    const exportarExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Estado");

      // Definir las columnas en Excel
      worksheet.columns = headers.map((header) => ({
        header: header.title,
        key: header.key,
        width: 25,
      }));

      // Añadir filas de datos
      filteredData.value.forEach((dato) => {
        worksheet.addRow({
          location: dato.location,
          count: dato.count,
        });
      });

      // Generar el buffer del archivo Excel
      const buffer = await workbook.xlsx.writeBuffer();
      // Descargar el archivo Excel
      saveAs(new Blob([buffer]), "estado.xlsx");
    };

    // Encabezados de la tabla
    const headers = [
      { title: "Localización", key: "location", sortable: true },
      { title: "Reader", key: "reader", sortable: true },
      { title: "Cantidad de Dispositivos", key: "count", sortable: true },
    ];

    // Cargar dispositivos al montar
    onMounted(async () => {
      if (deviceStore.devices.length === 0) {
        loading.value = true;
        await deviceStore.fetchDevices();
        loading.value = false;
      } else {
        loading.value = false;
      }
    });

    return {
      selectedFamily,
      onFamilyChange,
      familyOptions,
      filterLocation,
      headers,
      itemsPerPageOptions,
      pagination,
      loading,
      pageCount,
      totalDevices,
      exportarExcel,
      paginatedData,
      isAuthenticated,
    };
  },
};
</script>

<style scoped>
/* Estilos para mantener los headers fijos */
.v-data-table {
  overflow-y: auto;
  flex-grow: 1;
}

.v-data-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

/* Ajustar el padding en la fila del pie de página */
.pa-2 {
  padding: 0.5rem;
}

/* Estilos responsivos para alinear el botón correctamente */
@media (max-width: 600px) {
  .text-right {
    text-align: center !important;
  }

  .mr-4 {
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}
</style>
