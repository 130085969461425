<template>
  <v-container fluid class="fill-height d-flex align-center justify-center">
    <v-card class="pa-6" max-width="700" elevation="10">
      <v-img
        src="@/assets/imagen.png"
        alt="Login Image"
        class="mb-4"
        height="150"
        contain
      ></v-img>
      <v-card-title class="text-center text-h5 font-weight-bold">
        Bienvenido
      </v-card-title>
      <v-card-subtitle class="text-center mb-4">
        Ingrese sus credenciales
      </v-card-subtitle>
      <v-form>
        <v-text-field
          v-model="username"
          label="Usuario"
          outlined
          dense
          color="primary"
          class="mb-4"
          prepend-inner-icon="mdi-account"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="Contraseña"
          type="password"
          outlined
          dense
          color="primary"
          class="mb-4"
          prepend-inner-icon="mdi-lock"
          @keyup.enter="handleLogin"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark block @click="handleLogin"
            >Ingresar</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>

    <!-- Snackbar de bienvenida -->
    <v-snackbar
      v-model="showWelcomeMessage"
      :timeout="3000"
      color="success"
      top
      right
    >
      Bienvenido/a, {{ username }}.
    </v-snackbar>

    <!-- Snackbar de error -->
    <v-snackbar
      v-model="showErrorMessage"
      :timeout="3000"
      color="error"
      top
      right
    >
      Usuario o contraseña incorrectos.
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref } from "vue";
import { useAuthStore } from "@/stores/authStore";
import router from "@/router";

export default {
  setup() {
    const username = ref("");
    const password = ref("");
    const authStore = useAuthStore();
    const showWelcomeMessage = ref(false);
    const showErrorMessage = ref(false);

    const handleLogin = () => {
      authStore.login(username.value, password.value).then(() => {
        if (authStore.isAuthenticated) {
          showWelcomeMessage.value = true;
          // Redirigir a HomeView después de un breve retraso
          setTimeout(() => {
            router.push({ name: "Control" });
          }, 1500);
        } else {
          showErrorMessage.value = true;
        }
      });
    };

    return {
      username,
      password,
      handleLogin,
      showWelcomeMessage,
      showErrorMessage,
    };
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 20px;
  background: linear-gradient(135deg, #f9f9f9, #ffffff);
}
.v-img {
  border-radius: 50%;
  object-fit: contain;
  align-self: center;
}
.v-btn {
  font-size: 1.1rem;
  text-transform: none;
}
.v-card-title {
  color: #3b3b3b;
  font-size: 1.8rem;
}
.v-card-subtitle {
  color: #7d7d7d;
  font-size: 1.2rem;
}
</style>
