<!-- src/views/GraphicsView.vue -->
<template>
  <v-container v-if="isAuthenticated" fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filtroFamily"
          label="Graficar Familias"
          dense
          outlined
        ></v-text-field>
      </v-col>

      <!-- Gráfico de Pastel: Distribución de Dispositivos por Familia -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center"
            >Dispositivos por Familia</v-card-title
          >
          <PieChart
            :labels="familyLabels"
            :data="familyData"
            :backgroundColors="familyColors"
            chartTitle=""
          />
        </v-card>
      </v-col>

      <!-- Gráfico de Barras: Distribución de Dispositivos por Ubicación -->
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="justify-center"
            >Dispositivos por Ubicación</v-card-title
          >
          <BarChart
            :labels="locationLabels"
            :data="locationData"
            :backgroundColors="locationColors"
            chartTitle=""
            barLabel="Cantidad de Dispositivos"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useAuthStore } from "@/stores/authStore";
import PieChart from "@/components/PieChart.vue";
import BarChart from "@/components/BarChart.vue";

export default defineComponent({
  name: "GraphicsView",
  components: {
    PieChart,
    BarChart,
  },
  setup() {
    const deviceStore = useDeviceStore();
    deviceStore.setFiltroName("");
    deviceStore.setFiltroReader("");
    deviceStore.setFiltroLocation("");
    deviceStore.setFiltroDevicetype(null);
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);

    // Computed para etiquetas y datos del gráfico de pasteles (Familia)
    const familyLabels = computed(() => {
      return Object.keys(deviceStore.devicesCountByFamily);
    });

    const familyData = computed(() => {
      return Object.values(deviceStore.devicesCountByFamily);
    });

    const filtroFamily = computed({
      get: () => deviceStore.filtroNombre,
      set: (value) => {
        deviceStore.filtroNombre = value;
      },
    });

    const familyColors = computed(() => {
      // Generar colores aleatorios o definir una paleta
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#C9CBCF",
        "#8AFF33",
        "#FF33EC",
        "#33FFF6",
      ];
      // Repetir o seleccionar según la cantidad de etiquetas
      return familyLabels.value.map(
        (_, index) => colors[index % colors.length]
      );
    });

    // Computed para etiquetas y datos del gráfico de barras (Ubicación)
    const locationLabels = computed(() => {
      return Object.keys(deviceStore.devicesCountByLocationGraph);
    });

    const locationData = computed(() => {
      return Object.values(deviceStore.devicesCountByLocationGraph);
    });

    const locationColors = computed(() => {
      // Generar colores aleatorios o definir una paleta
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#C9CBCF",
        "#8AFF33",
        "#FF33EC",
        "#33FFF6",
      ];
      // Repetir o seleccionar según la cantidad de etiquetas
      return locationLabels.value.map(
        (_, index) => colors[index % colors.length]
      );
    });

    // Cargar dispositivos al montar
    onMounted(async () => {
      if (deviceStore.devices.length === 0) {
        await deviceStore.fetchDevices();
      }
    });

    return {
      familyLabels,
      familyData,
      familyColors,
      locationLabels,
      locationData,
      locationColors,
      isAuthenticated,
      filtroFamily,
    };
  },
});
</script>

<style scoped>
.v-card {
  min-height: 400px;
}
</style>
