<!-- src/views/SpecialView.vue -->
<template>
  <v-container v-if="isAuthenticated" fluid>
    <v-row>
      <v-col cols="12">
        <FloorPlanCanvas />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref } from "vue";
import FloorPlanCanvas from "@/components/FloorPlanCanvas.vue";
import { useAuthStore } from "@/stores/authStore";

export default {
  name: "SpecialView",
  components: {
    FloorPlanCanvas,
  },
  setup() {
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);

    return { isAuthenticated };
  },
};
</script>

<style scoped>
/* Agrega estilos personalizados si es necesario */
</style>
