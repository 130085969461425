const baseURL = process.env.VUE_APP_API_URL;

export default {
  async getAllDevices() {
    const url = `${baseURL}/api/devices`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data; // Accedemos al arreglo de dispositivos
  },

  async getFamilies() {
    const url = `${baseURL}/api/families`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data; // Accedemos al arreglo de dispositivos
  },

  async getDevicesInBounds(minLat, minLon, maxLat, maxLon) {
    const url = `${baseURL}/api/devices_in_bounds?minlat=${minLat}&minlon=${minLon}&maxlat=${maxLat}&maxlon=${maxLon}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data; // Accedemos al arreglo de dispositivos
  },

  async getConteoDispositivos() {
    const url = `${baseURL}/api/contar_dispositivos`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data; // Accedemos al arreglo de dispositivos
  },

  async login(username, password) {
    const url = `${baseURL}/api/login`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Error en la petición: ${response.statusText}`);
      }

      const status = response.status;
      const data = await response.json();
      console.log(data);
      return { status, data }; // Por ejemplo, `data` podría contener un token o un mensaje
    } catch (error) {
      console.error("Error al intentar iniciar sesión:", error);
      throw error;
    }
  },

  async validateToken() {
    const url = `${baseURL}/api/validate_token`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    return response;
  },

  async logout() {
    const url = `${baseURL}/api/logout`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }

    return response;
  },

  async getTableData() {
    const url = `${baseURL}/api/get_table_data`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(`Error en la petición: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data; // Accedemos al arreglo de datos de la tabla
  },

  async getManuales() {
    const url = `${baseURL}/api/manuales`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `Error al obtener el menú de manuales: ${response.statusText}`
      );
    }
    return await response.json();
  },

  async getPdfUrl(docId) {
    const url = `${baseURL}/api/manuales/pdf/${encodeURIComponent(docId)}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `Error al obtener la URL del PDF: ${response.statusText}`
      );
    }
    return await response.json();
  },
};
