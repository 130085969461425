<template>
  <v-card
    v-if="isAuthenticated"
    class="d-flex flex-column"
    style="height: calc(100vh - 80px); overflow: hidden"
  >
    <!-- Indicador de carga -->
    <v-progress-linear
      :active="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <!-- Título de la tarjeta y filtros -->
    <v-card-title v-if="!loading">
      <v-row class="w-100" no-gutters>
        <!-- Campo de búsqueda por nombre -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="filtroNombre"
            label="Buscar por Nombre"
            single-line
            hide-details
            dense
            outlined
            class="mr-2"
          ></v-text-field>
        </v-col>

        <!-- Selector de tipo de dispositivo -->
        <v-col cols="12" md="4">
          <v-select
            v-model="filtroDeviceType"
            :items="deviceTypeOptions"
            label="Tipo de dispositivo"
            item-title="title"
            item-value="value"
            clearable
            dense
            outlined
            class="mr-2"
          ></v-select>
        </v-col>

        <!-- Campo de búsqueda por localización -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="filtroLocation"
            label="Buscar por Localización"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>

        <!-- Campo de búsqueda por Reader -->
        <v-col cols="12" md="4">
          <v-text-field
            v-model="filtroReader"
            label="Buscar por Reader"
            single-line
            hide-details
            dense
            outlined
            class="mr-2"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>

    <!-- Tabla con encabezados fijos y altura ajustable -->
    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items="datosParaTabla"
      v-model:page="page"
      v-model:items-per-page="itemsPerPage"
      class="elevation-1 flex-grow-1"
      fixed-header
      hide-default-footer
      style="overflow: hidden"
    >
      <!-- Slot personalizado para el pie de página -->
      <template #bottom>
        <v-row align="center" justify="space-between" class="w-100 pa-2">
          <!-- Selector de Filas por Página y Paginación -->
          <v-col cols="12" sm="8" class="d-flex align-center">
            <!-- Selector de Filas por Página -->
            <v-select
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              item-title="title"
              item-value="value"
              label="Filas por página"
              hide-details
              dense
              outlined
              class="mr-4"
              style="max-width: 250px; min-width: 200px"
            ></v-select>
            <!-- Paginación -->
            <v-pagination
              v-model="page"
              :length="pageCount"
              total-visible="3"
            ></v-pagination>
            <!-- Indicador del número total de dispositivos -->
            <span class="ml-4">
              Total de dispositivos: {{ totalDevices }}
            </span>
          </v-col>
          <!-- Botón de Exportar a Excel -->
          <v-col cols="8" sm="4" class="text-right">
            <v-btn
              color="success"
              dark
              @click="exportarExcel"
              class="mt-2 mt-sm-0"
              small
              aria-label="Exportar a Excel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Exportar a Excel
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useDeviceStore } from "@/stores/deviceStore";
import { useAuthStore } from "@/stores/authStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  name: "InventoryView",
  setup() {
    const deviceStore = useDeviceStore();
    const authStore = useAuthStore();
    const loading = ref(true);
    const isAuthenticated = ref(authStore.isAuthenticated);

    // Filtros reactivos vinculados al store
    const filtroNombre = computed({
      get: () => deviceStore.filtroNombre,
      set: (value) => {
        deviceStore.filtroNombre = value;
      },
    });

    const filtroReader = computed({
      get: () => deviceStore.filtroReader,
      set: (value) => {
        deviceStore.filtroReader = value;
      },
    });

    const filtroDeviceType = computed({
      get: () => deviceStore.filtroDeviceType,
      set: (value) => {
        deviceStore.filtroDeviceType = value;
      },
    });

    const filtroLocation = computed({
      get: () => deviceStore.filtroLocation,
      set: (value) => {
        deviceStore.filtroLocation = value;
      },
    });

    const itemsPerPage = ref(10);
    const page = ref(1);

    const deviceTypeOptions = ref([]);

    const itemsPerPageOptions = [
      { title: "10", value: 10 },
      { title: "20", value: 20 },
      { title: "50", value: 50 },
      { title: "100", value: 100 },
      { title: "500", value: 500 },
      { title: "1000", value: 1000 },
    ];

    const datosParaTabla = computed(() => {
      return deviceStore.devicesFiltrados.map((device) => ({
        ...device,
        LOCATION: device.Location || "Sin ubicación",
      }));
    });

    const headers = [
      { title: "Nombre", key: "Name", sortable: true },
      { title: "Estado", key: "statusKey", sortable: true },
      { title: "Localización", key: "LOCATION", sortable: true },
      { title: "Reader", key: "Reader", sortable: true },
      { title: "Última Actualización", key: "updated", sortable: true },
    ];

    const pageCount = computed(() => {
      if (itemsPerPage.value === -1) {
        return 1; // Mostrar todas las filas en una sola página
      }
      return Math.ceil(datosParaTabla.value.length / itemsPerPage.value);
    });

    // Computed para el número total de dispositivos
    const totalDevices = computed(() => {
      return datosParaTabla.value.length;
    });

    const exportarExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Inventario");

      // Definir las columnas en Excel
      worksheet.columns = headers.map((header) => ({
        header: header.title,
        key: header.key,
        width: 20,
      }));

      // Añadir filas de datos
      datosParaTabla.value.forEach((device) => {
        worksheet.addRow({
          Name: device.Name,
          statusKey: device.statusKey,
          LOCATION: device.LOCATION,
          updated: device.updated,
        });
      });

      // Generar el buffer del archivo Excel
      const buffer = await workbook.xlsx.writeBuffer();
      // Descargar el archivo Excel
      saveAs(new Blob([buffer]), "Inventario.xlsx");
    };

    onMounted(async () => {
      // Llamar a la API para obtener los dispositivos
      if (!deviceStore.devices.length) {
        await deviceStore.fetchDevices();
        onDataLoaded();
      }

      // Aplicar el filtro de familia si está definido
      if (deviceStore.filtroFamily) {
        filtroNombre.value = deviceStore.filtroFamily;
      }

      // Iniciar la actualización periódica desde el store
      deviceStore.startPeriodicUpdate();

      // Obtener las opciones de DeviceType
      const deviceTypes = new Set(
        deviceStore.devices.map((device) => device.DeviceType)
      );
      // Crear un diccionario para hacer el mapeo de los tipos de dispositivo a títulos descriptivos
      const deviceTypeTitles = {
        54: "Reader",
        55: "Beacon/Tracker",
        64: "Smart Beacon/Tracker",
      };
      deviceTypeOptions.value = Array.from(deviceTypes)
        .filter((type) => type !== undefined && type !== null)
        .map((type) => ({
          title: deviceTypeTitles[type] || `Tipo ${type}`, // Si no está definido, mostrar "Tipo {type}"
          value: type,
        }));

      loading.value = false;
    });

    const onDataLoaded = () => {
      // Actualizar el estado de carga a false cuando los datos hayan sido cargados
      loading.value = false;
    };

    return {
      loading,
      filtroNombre,
      filtroDeviceType,
      filtroLocation,
      deviceTypeOptions,
      itemsPerPage,
      page,
      itemsPerPageOptions,
      datosParaTabla,
      headers,
      exportarExcel,
      pageCount,
      totalDevices,
      isAuthenticated,
      filtroReader,
    };
  },
};
</script>

<style scoped>
th {
  color: black !important;
}

/* Ajustar el padding en la fila del pie de página */
.pa-2 {
  padding: 0.5rem;
}

/* Estilos responsivos para alinear el botón correctamente */
@media (max-width: 600px) {
  .text-right {
    text-align: center !important;
  }

  .mr-4 {
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}

/* Asegurar que la tabla no exceda la altura del contenedor */
.v-data-table {
  overflow: auto;
}
</style>
