import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components"; // Importar todos los componentes
import * as directives from "vuetify/directives";

export default createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#4a4d4e",
          onPrimary: "#ffffff",
        },
      },
    },
  },
});
