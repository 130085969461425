<!-- src/components/Navbar.vue -->
<template>
  <v-app-bar app color="primary" dark>
    <!-- Título de la aplicación, siempre visible y ajustable -->
    <v-toolbar-title class="title">Axia Monitoring</v-toolbar-title>

    <!-- Espaciador para empujar elementos a la derecha -->
    <v-spacer></v-spacer>

    <!-- Enlaces de navegación (visibles solo en pantallas medianas y grandes) -->
    <v-toolbar-items class="d-none d-lg-flex">
      <v-btn text to="/"><v-icon left>mdi-map-marker</v-icon> Mapa </v-btn>
      <v-btn text to="/inventory"
        ><v-icon left>mdi-database</v-icon> Inventario</v-btn
      >
      <v-btn text to="/status"
        ><v-icon left>mdi-information-variant-circle-outline</v-icon>
        Estados</v-btn
      >
      <v-btn text to="/graphics"
        ><v-icon left>mdi-chart-box</v-icon> Gráficos</v-btn
      >
      <v-btn text to="/special"
        ><v-icon left>mdi-map-search</v-icon> Planos</v-btn
      >
      <v-btn text to="/Logistics"
        ><v-icon left>mdi-table</v-icon> Tabla MF</v-btn
      >
      <v-btn text to="/NewData"
        ><v-icon left>mdi-table-large</v-icon> Eventos</v-btn
      >
    </v-toolbar-items>

    <!-- Menú desplegable de navegación (visible solo en pantallas pequeñas) -->
    <v-menu offset-y class="d-lg-none">
      <template #activator="{ props }">
        <v-btn icon v-bind="props" class="d-lg-none">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in navItems"
          :key="index"
          @click="navigate(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Menú desplegable del usuario-->
    <v-menu v-if="authStore.isAuthenticated" offset-y>
      <template #activator="{ props }">
        <v-btn icon v-bind="props">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ authStore.user }}</v-list-item-title>
            <v-list-item-subtitle>Bienvenido/a</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="navigate('/Manuales')">
          <v-list-item-title>
            <v-icon>mdi-book-open-page-variant</v-icon>Manuales
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-title>
            <v-icon>mdi-logout</v-icon>Logout
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { useAuthStore } from "@/stores/authStore";
import router from "@/router";

export default {
  name: "NavBar",
  setup() {
    const authStore = useAuthStore();

    const navItems = [
      { text: "Mapa", route: "/", icon: "mdi-map-marker" },
      { text: "Inventario", route: "/inventory", icon: "mdi-database" },
      {
        text: "Estados",
        route: "/status",
        icon: "mdi-information-variant-circle-outline",
      },
      { text: "Gráficos", route: "/graphics", icon: "mdi-chart-box" },
      { text: "Planos", route: "/special", icon: "mdi-map-search" },
      { text: "Tabla MF", route: "/Logistics", icon: "mdi-table" },
      { text: "Eventos", route: "/NewData", icon: "mdi-table-large" },
    ];

    const handleLogout = () => {
      authStore.logout();
      router.push({ name: "Login" });
    };

    const navigate = (route) => {
      router.push(route);
    };

    return {
      authStore,
      navItems,
      handleLogout,
      navigate,
    };
  },
};
</script>

<style scoped>
/* Estilos específicos para la navbar */
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
}

/* Ocultar el menú desplegable del icono en pantallas grandes */
.d-lg-none {
  display: none;
}

@media (max-width: 1280px) {
  .d-lg-none {
    display: inline-flex;
  }
  .d-lg-flex {
    display: none;
  }
}
</style>
