<template>
  <v-card
    class="d-flex flex-column"
    style="height: calc(100vh - 100px); overflow: hidden"
  >
    <v-card-title>
      <!-- Campo de búsqueda -->
      <v-text-field
        v-model="filtroLocal"
        @input="actualizarFiltro"
        label="Buscar..."
        single-line
        hide-details
        dense
        outlined
        class="mr-4"
      ></v-text-field>
      <v-spacer></v-spacer>
      <!-- Selector de tipo de dispositivo -->
      <v-select
        v-model="deviceTypeFilter"
        @update:model-value="actualizarFiltroDeviceType"
        :items="deviceTypeOptions"
        label="Tipo de dispositivo"
        item-title="title"
        item-value="value"
        clearable
        dense
        outlined
        class="mr-4"
      ></v-select>
    </v-card-title>

    <!-- Tabla con encabezados fijos y altura ajustable -->
    <v-data-table
      :headers="headers"
      :items="datosParaTabla"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      class="elevation-1 flex-grow-1"
      fixed-header
      hide-default-footer
      style="overflow: hidden"
    >
      <!-- Slot personalizado para el pie de página -->
      <template #bottom>
        <!-- Layout pantallas pequeñas: dos filas -->
        <div class="d-flex d-md-none flex-column w-100 pa-2">
          <!-- Primera fila: Selector y Paginación -->
          <v-row class="w-100 d-flex align-center justify-start mb-2 flex-wrap">
            <v-col cols="12" class="mb-2">
              <v-select
                v-model="selectedItemsPerPage"
                :items="itemsPerPageOptions"
                label="Filas por página"
                hide-details
                dense
                outlined
                style="max-width: 150px"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                total-visible="2"
              ></v-pagination>
            </v-col>
          </v-row>

          <!-- Segunda fila: Total a la izquierda, Botón a la derecha -->
          <v-row class="w-100 d-flex align-center justify-space-between">
            <v-col cols="auto" class="d-flex align-center">
              <span>Total: {{ totalDevices }}</span>
            </v-col>
            <v-col cols="auto" class="d-flex justify-end">
              <v-btn
                color="success"
                dark
                @click="exportarExcel"
                small
                aria-label="Exportar a Excel"
              >
                <v-icon left>mdi-file-excel</v-icon>
                Exportar a Excel
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <!-- Layout pantallas grandes: todo en una sola fila -->
        <div
          class="d-none d-md-flex w-100 pa-2 align-center justify-space-between flex-wrap"
        >
          <div class="d-flex align-center flex-wrap">
            <v-select
              v-model="selectedItemsPerPage"
              :items="itemsPerPageOptions"
              label="Filas por página"
              hide-details
              dense
              outlined
              style="max-width: 150px"
              class="mr-4"
            ></v-select>

            <v-pagination
              v-model="page"
              :length="pageCount"
              total-visible="2"
              class="mr-4"
            ></v-pagination>

            <span>Total: {{ totalDevices }}</span>
          </div>

          <div class="d-flex">
            <v-btn
              color="success"
              dark
              @click="exportarExcel"
              small
              aria-label="Exportar a Excel"
            >
              <v-icon left>mdi-file-excel</v-icon>
              Exportar a Excel
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useDatosStore } from "@/stores/datosStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default {
  name: "TableComponent",
  emits: ["visible-devices-changed"], // Agregamos el evento que emitiremos
  setup(_, { emit }) {
    const datosStore = useDatosStore();
    const filtroLocal = ref(datosStore.filtroNombre);
    const deviceTypeFilter = ref(datosStore.filtroDeviceType);
    const itemsPerPage = ref(10);
    const page = ref(1);
    const selectedItemsPerPage = ref(10);

    const actualizarFiltro = () => {
      datosStore.setFiltroNombre(filtroLocal.value);
    };

    const actualizarFiltroDeviceType = () => {
      datosStore.setFiltroDeviceType(deviceTypeFilter.value);
    };

    const deviceTypeOptions = [
      { title: "Smart Beacon", value: "64" },
      { title: "Beacon", value: "55" },
      { title: "Reader", value: "54" },
    ];

    const itemsPerPageOptions = [
      { title: "10", value: 10 },
      { title: "20", value: 20 },
      { title: "50", value: 50 },
      { title: "100", value: 100 },
      { title: "500", value: 500 },
      { title: "1000", value: 1000 },
      { title: "Todas", value: -1 },
    ];

    const datosParaTabla = computed(() => {
      return datosStore.datosFiltrados.map((dato) => ({
        ...dato,
        LOCATION: dato.fields?.LOCATION || "Sin ubicación",
      }));
    });

    const headers = [
      { title: "Nombre", key: "Name", sortable: true },
      { title: "Estado", key: "statusKey", sortable: true },
      { title: "Localización", key: "LOCATION", sortable: true },
      { title: "Reader", key: "Reader", sortable: true },
      { title: "Última actualización", key: "updated", sortable: true },
    ];

    const totalDevices = computed(() => {
      return datosParaTabla.value.length;
    });

    const pageCount = computed(() => {
      if (selectedItemsPerPage.value === -1) {
        return 1;
      }
      return Math.ceil(
        datosStore.datosFiltrados.length / selectedItemsPerPage.value
      );
    });

    // Computed para obtener los dispositivos de la página actual
    const currentPageItems = computed(() => {
      if (selectedItemsPerPage.value === -1) {
        return datosParaTabla.value;
      } else {
        const start = (page.value - 1) * itemsPerPage.value;
        const end = start + itemsPerPage.value;
        return datosParaTabla.value.slice(start, end);
      }
    });

    watch(selectedItemsPerPage, (newVal) => {
      if (newVal === -1) {
        itemsPerPage.value = datosParaTabla.value.length;
      } else {
        itemsPerPage.value = newVal;
      }
      page.value = 1;
    });

    // Cada vez que cambie la paginación o los datos filtrados, emitimos los dispositivos visibles
    watch([page, selectedItemsPerPage, datosParaTabla], () => {
      emit("visible-devices-changed", currentPageItems.value);
    });

    const exportarExcel = async () => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Datos");

      worksheet.columns = headers.map((header) => ({
        header: header.title,
        key: header.key,
        width: 20,
      }));

      datosParaTabla.value.forEach((dato) => {
        worksheet.addRow({
          Name: dato.Name,
          LOCATION: dato.LOCATION,
          statusKey: dato.statusKey,
          updated: dato.updated,
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), "datos.xlsx");
    };

    return {
      filtroLocal,
      actualizarFiltro,
      deviceTypeFilter,
      actualizarFiltroDeviceType,
      deviceTypeOptions,
      itemsPerPage,
      page,
      selectedItemsPerPage,
      itemsPerPageOptions,
      datosParaTabla,
      headers,
      exportarExcel,
      pageCount,
      totalDevices,
    };
  },
};
</script>

<style scoped>
th {
  color: black !important;
}

/* Ajustar el padding en la fila del pie de página */
.pa-2 {
  padding: 0.5rem;
}

/* Estilos responsivos para alinear el botón correctamente */
@media (max-width: 600px) {
  .text-right {
    text-align: center !important;
  }

  .mr-4 {
    margin-right: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}

/* Asegurar que la tabla no exceda la altura del contenedor */
.v-data-table {
  overflow: auto;
}
</style>
