<!-- src/App.vue -->
<template>
  <v-app>
    <NavBar v-if="showNavbar" />
    <v-main app>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import { useAuthStore } from "./stores/authStore";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    NavBar,
  },
  setup() {
    const authStore = useAuthStore();
    const route = useRoute(); // Accedemos a la ruta actual
    const showNavbar = ref(true);

    // Función para actualizar la visibilidad de la Navbar
    const updateNavbarVisibility = () => {
      showNavbar.value = route.name !== "Control"; // Cambiar si es necesario el nombre exacto de la vista
    };

    onMounted(() => {
      authStore.checkAuthentication();
      updateNavbarVisibility();
    });

    // Verificar cambios en la ruta y actualizar la visibilidad de la Navbar
    watch(
      () => route.name,
      () => {
        updateNavbarVisibility();
      }
    );

    return {
      showNavbar,
    };
  },
};
</script>
