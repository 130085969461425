import { defineStore } from "pinia";
import api from "@/services/api";

export const useLogiStore = defineStore("LogiStore", {
  state: () => ({
    fetchedData: {},
    isLoading: false,
    error: null,
  }),
  getters: {
    getDataByFamily: (state) => {
      return (familyId) => state.fetchedData[familyId] || {};
    },
    getTotalByFamily: (state) => {
      return (familyId) => {
        const familyData = state.fetchedData[familyId];
        if (!familyData) return 0;
        return Object.values(familyData).reduce((acc, curr) => acc + curr, 0);
      };
    },
  },
  actions: {
    async fetchData() {
      this.isLoading = true;
      this.error = null;
      try {
        const data = await api.getConteoDispositivos();
        this.fetchedData = data;
      } catch (err) {
        this.error = err.message || "Error al obtener los datos";
      } finally {
        this.isLoading = false;
      }
    },
  },
});
