// src/data/nodos.js
export const nodos = [
  { id: "Descarga Llenos", x: 895, y: 400 },
  { id: "Gestamp", x: 920, y: 100 },
  { id: "Almacén Lleno", x: 600, y: 350 },
  { id: "Almacén Vacío", x: 555, y: 420 },
  { id: "Trasvase", x: 480, y: 150 },
  { id: "En Consumo", x: 615, y: 450 },
  { id: "Revisión de Calidad", x: 410, y: 110 },
  { id: "Almacén de Recuperación", x: 260, y: 850 },
  { id: "En Reparación", x: 50, y: 340 },
  { id: "Perdido", x: 50, y: 100 },
  { id: "Tránsito Inusual", x: 50, y: 220 },
];

export const edges = [
  { from: "Descarga Llenos", to: "Almacén Lleno" },
  { from: "Almacén Lleno", to: "En Consumo" },
  { from: "En Consumo", to: "Almacén Vacío" },
  { from: "Almacén Vacío", to: "Descarga Llenos" },
  { from: "Descarga Llenos", to: "Gestamp" },
];
