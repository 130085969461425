<template>
  <v-container v-if="isAuthenticated" fluid class="fill-height pa-0 ma-0">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <v-row class="fill-height pa-0 ma-0">
      <!-- Mapa: en pantallas grandes lg="3", en pequeñas ocupa todo el ancho -->
      <v-col
        cols="12"
        sm="3"
        md="3"
        lg="3"
        class="fill-height pa-2 d-flex flex-column"
      >
        <div class="map-wrapper">
          <MapComponent
            :visibleDevices="visibleDevices"
            @data-loaded="onDataLoaded"
          />
        </div>
      </v-col>

      <!-- Tabla: en pantallas grandes lg="9", más espacio que el mapa -->
      <v-col
        cols="12"
        sm="9"
        md="9"
        lg="9"
        class="fill-height pa-2 d-flex flex-column"
      >
        <div class="table-wrapper">
          <TableComponent @visible-devices-changed="onVisibleDevicesChanged" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from "vue";
import { useDatosStore } from "@/stores/datosStore";
import { useAuthStore } from "@/stores/authStore";
import MapComponent from "@/components/MapComponent.vue";
import TableComponent from "@/components/TableComponent.vue";

export default {
  name: "HomeView",
  components: {
    MapComponent,
    TableComponent,
  },
  setup() {
    const datosStore = useDatosStore();
    const authStore = useAuthStore();
    const isAuthenticated = ref(authStore.isAuthenticated);
    const loading = ref(true);
    const visibleDevices = ref([]);

    onMounted(() => {
      loading.value = true;
    });

    const onDataLoaded = () => {
      loading.value = false;
    };

    const onVisibleDevicesChanged = (devices) => {
      visibleDevices.value = devices;
    };

    return {
      datosStore,
      onDataLoaded,
      loading,
      isAuthenticated,
      visibleDevices,
      onVisibleDevicesChanged,
    };
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
}

.map-wrapper {
  width: 100%;
  height: calc(100vh - 100px);
  transition: height 0.3s;
  padding: 0;
  margin: 0;
}

/* En pantallas pequeñas (portrait), reducir el mapa 
@media (max-width: 600px) and (orientation: portrait) {
  .map-wrapper {
    height: 200px;
  }
}*/

/* En pantallas pequeñas (landscape), un poco diferente */
@media (max-width: 600px) and (orientation: landscape) {
  .map-wrapper {
    height: 250px;
  }
}
</style>
