<!-- src/views/TableView.vue -->
<template>
  <v-container v-if="isAuthenticated" fluid class="fill-height pa-0 ma-0">
    <!-- Barra de progreso -->
    <v-progress-linear
      v-if="tableStore.loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <!-- Tabla de datos -->
    <v-data-table
      v-else
      :headers="headers"
      :items="tableStore.data"
      class="elevation-1"
      :items-per-page="10"
    >
    </v-data-table>

    <v-alert v-if="tableStore.error" type="error" dismissible>
      {{ tableStore.error }}
    </v-alert>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { useTableStore } from "@/stores/tableStore";

const authStore = useAuthStore();
const tableStore = useTableStore();

const isAuthenticated = ref(authStore.isAuthenticated);

onMounted(async () => {
  if (isAuthenticated.value) {
    await tableStore.fetchTableData();
  }
});

// Encabezados de la tabla basados en los campos que se devuelven de la consulta
const headers = [
  { title: "Dispositivo", value: "Dispositivo" },
  { title: "Lector", value: "Lector" },
  { title: "Time", value: "Time" },
  { title: "Variable", value: "Variable" },
  { title: "Valor", value: "Valor" },
];
</script>

<style scoped>
.elevation-1 {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
